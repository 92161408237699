<template>
  <div class="component-fullwidth">
    <div class="flex-container flex-full no-padding no-margin">
      <div class="elem-flex">
        <div class="title-page">
          Forum
        </div>
        <div class="subtitle-page wother speWidth">
          <div>
            <span class="clickable" @click="goBack">{{thread.category.title}}</span > >
            <span>{{title}}</span>
            <!-- {{thread}} -->
          </div>
          <div class="other">
            <div class="btn-green radius-small small reverse"
              @click="goBack">Go Back</div>
          </div>
        </div>
      </div>
    </div>
    <div id="listcategoriesThread" class="cont-forum-inner">
      <div class="elem-forum" v-for="post in posts" :key="post._id">
        <post :post="post" />
        <div class="cont-comment">
          <post v-for="comment in post.comments" :key="comment._id" :post="comment" />
        </div>
        <div class="cont-btn-reply" >
          <div v-if="!isMe(post.author)"
            class="btn-green small radius-small" @click="() => comment(post)">
            Reply
          </div>
          <div v-else class="btn-green small radius-small" @click="() => editOwnPost(post)">
            Edit
          </div>
        </div>
      </div>
      <div class="add-forum radius" v-if="newPostForm">
        <div class="head" v-if="commentPost">
          <div
            class="picto"
            v-if="$store.state.user.profilePicture"
            :style="`background-image:url(${$store.state.user.profilePicture})`"
          ></div>
          <div class="picto" v-else></div>
          <div class="info">
            <div class="top-info">
              <p>
                {{$store.state.user.name.firstName}} {{$store.state.user.name.lastName}}
              </p>
            </div>
            <div class="bot-info">
              <p>
                <i>
                  Reply to
                  {{commentPost.author.name.firstName}} {{commentPost.author.name.lastName}}
                </i>
              </p>
            </div>

            <div class="message radius-small" v-html="commentPost.body"></div>
          </div>
        </div>
        <wysiwyg class="radius" v-model="postBody" />
        <div class="cont-submit-forum-btn">
          <div class="btn-green radius reverse" @click="() => toggleEdit()">Cancel</div>
          <div class="btn-green radius" @click="submit"> Publish</div>
        </div>

      </div>
      <div class="cont-reply">
        <div class="btn-green radius-small"
          v-if="!newPostForm"
          @click="() =>toggleEdit()"
        >
          Reply to thread
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import Post from '@/components/UniversalComponents/Forum/forumPost.vue';
import liveReload from '@/modules/mixins/forum/liveReload';

export default {
  name: 'ForumThread',
  components: {
    Post,
  },
  mixins: [liveReload],
  data() {
    return {
      thread: {},
      posts: [],
      title: '',
      postBody: '',
      newPostForm: false,
      commentPost: false,
      editPost: false,
    };
  },
  mounted() {
    this.$api.get(`forum/thread/${this.$route.params.id}`).then((res) => {
      this.thread = res.data;
      this.posts = res.data.posts || [];
      this.title = res.data.title;
      // const ps = new PerfectScrollbar('#listcategoriesThread', {
      //   wheelPropagation: false,
      //   wheelSpeed: 1,
      //   suppressScrollX: true,
      // });
      // ps.update();
      setTimeout(() => {
        const psSteps = new PerfectScrollbar('#listcategoriesThread', {
          wheelPropagation: false,
          wheelSpeed: 1,
        });
        psSteps.update();
      }, 100);
    });
    this.isMe();
  },
  methods: {
    toggleEdit(commentPost = false) {
      this.newPostForm = !this.newPostForm;
      if (this.newPostForm) {
        const objDiv = document.getElementById('listcategoriesThread');
        this.$nextTick(() => {
          objDiv.scrollTop = objDiv.scrollHeight;
        });
      }
      this.commentPost = commentPost;
      this.editPost = false;
    },

    editOwnPost(post) {
      this.postBody = post.body;
      this.newPostForm = true;
      this.editPost = post;
    },
    submit() {
      if (this.commentPost) {
        this.submitComment();
      } else if (this.editPost) {
        this.submitEditPost();
      } else {
        this.submitPost();
      }
    },
    submitPost() {
      this.toggleEdit();
      this.$api.post('forum/post', { parent: this.$route.params.id, body: this.postBody }).then((res) => {
        this.postBody = '';
        this.posts.push(res.data);
      });
    },
    submitComment() {
      const commentPostId = this.commentPost._id;
      this.toggleEdit();
      this.$api.post('forum/comment', { parent: commentPostId, body: this.postBody }).then((res) => {
        this.postBody = '';
        this.posts.find((post) => post._id === commentPostId).comments.push(res.data);
      });
    },

    submitEditPost() {
      this.newPostForm = false;
      this.$api.put('forum/post', { _id: this.editPost._id, body: this.postBody }).then((res) => {
        this.postBody = '';
        this.editPost.body = res.data.body;
        this.editPost = false;
      });
    },
    comment(post) {
      this.toggleEdit(post);
    },
    goBack() {
      this.$router.back();
    },
    isMe(author) {
      if (author && author._id === this.$store.state.user._id) {
        return true;
      }
      return false;
    },
  },

};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .subtitle-page{
    color: $text_color;
    font-weight: 600;
    span{
      font-weight: 400;
      color: $logo_color;
      &.clickable{
        color: $text_color;
        font-weight: 600;
        cursor: pointer;
        &:hover{
           color: $logo_color;
        }
      }
    }
  }
  .cont-forum-inner{
    overflow: hidden;
    position: relative;
    margin-top: 4vh;
    height: 67vh;
    padding: 0.5vh;
    padding-right: 1vh;
    .elem-forum{
      width: 100%;
      margin-bottom: 1.5vh;
      .forum-post{
      }
      .cont-comment{
        margin-left: 10vh;
      }
      .cont-btn-reply{
        margin-top: 0.75vh;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .add-forum{
    border: 1px solid $logo_color;
    background-color: $new_bg_grey;
    padding: 1vh;
    .head{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 1vh;
      .picto{
        background-color: $logo_color;
        height: 4.5vh;
        width: 4.5vh;
        border: 0.1vh solid $logo_color;
        background-size: cover;
        background-position: 50% 50%;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 1.5vh;
      }
      .info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 1.6vh;
        .top-info{
          p{
            white-space: nowrap;
            width: 23.5vh;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .bot-info{
          margin-top: 0.5vh;
          font-size: 1.2vh;
          display: flex;
          flex-direction: row;
          > p{
            color: $logo_color;
          }
        }
        .message{
          background-color: $text_color;
          color: #ffffff;
          font-size: 1.2vh;
          padding: 0.5vh;
          margin-top: 0.5vh;
          font-style: italic;
        }
      }
    }
  }
  .cont-reply{
    display: flex;
    justify-content: flex-end;
  }
</style>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .subtitle-page{
    &.speWidth{
      width: calc(100% - 1vh);
    }
  }
</style>
