<template>
  <div
    class="forum-post"
    :class="isMe(this.post.author) ? 'mine' : ''"
  >
    <div class="head">
      <div
        class="picto"
        v-if="post.author.profilePicture"
        :style="`background-image:url(${post.author.profilePicture})`"
      ></div>
      <div class="picto" v-else></div>
      <div class="info">
        <div class="top-info">
          <p>
            {{post.author.name.firstName}} {{post.author.name.lastName}}
          </p>
        </div>
        <div class="bot-info">
          <div class="">
            {{post.createdAt | moment('dddd, MMMM Do YYYY')}}
          </div>
          <div class="">
            {{post.createdAt | moment('HH:mm')}}
          </div>
        </div>
      </div>
    </div>

    <div class="content-forum-post box-shadow radius" v-html="this.post.body"></div>

    <slot></slot>
    <!-- {{this.post.author}} -->
  </div>
</template>

<script>
// import { mapState } from 'vuex';

export default {
  name: 'ForumPost',
  props: ['post'],
  computed: {
    // ...mapState('user', ['userInfo']),
  },
  mounted() {
    // this.$store.dispatch('user/getUserInfo');
    this.isMe();
  },
  methods: {
    gotoprofile() {
      if (this.post.author.__t === 'Employee') {
        window.open(`${window.location.origin}/company/${this.post.author.company}`, '_blank');
      } else {
        window.open(`${window.location.origin}/talent/${this.post.author._id}`, '_blank');
      }
    },
    isMe(author) {
      if (author && author._id === this.$store.state.user._id) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .forum-post{
    cursor: initial !important;
    flex-wrap: wrap;
    .head{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      .picto{
        background-color: $logo_color;
        height: 4.5vh;
        width: 4.5vh;
        border: 0.1vh solid $logo_color;
        background-size: cover;
        background-position: 50% 50%;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 1.5vh;
      }
      .info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 1.6vh;
        .top-info{
          p{
            white-space: nowrap;
            width: 23.5vh;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .bot-info{
          margin-top: 0.5vh;
          font-size: 1.2vh;
          display: flex;
          flex-direction: row;
          div{
            margin-right: 1vh;
            &:last-child{
              margin-right: 0;
            }
          }
        }
      }
    }
    .content-forum-post{
      width: auto;
      display: inline-block;
      margin-top: 1vh;
      padding: 1vh;
      background-color: $new_bg_grey;
    }
    .goprofile{
      .btn-green{
        margin-top: 0.5vh;
        display: inline-block;
      }
    }
  }
</style>
