export default {
  data() {
    return {
      listening: false,
    };
  },
  mounted() {
    this.listen();
  },
  beforeDestroy() {
    this.$socket.client.off(`threadNotifications/${this.$route.params.id}`, this.onListen);
  },

  methods: {
    listen() {
      this.listening = `threadNotifications/${this.$route.params.id}`;
      this.$socket.client.on(this.listening, this.onListen);
    },
    onListen(payload) {
      if (payload.author._id !== this.$store.state.user._id) {
        if (payload.parent === this.$route.params.id) {
          this.posts.push(payload);
        } else {
          this.posts.find((post) => post._id === payload.parent).comments.push(payload);
        }
      }
    },

  },

};
